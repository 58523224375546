// import axios from 'axios';
import {
    Box,
    Grid,
    Input,
    Button,
    useDisclosure,
  } from "@chakra-ui/react";
  
  import Card from "components/card/Card";
  import SuccessModal from "components/succesModal/succesModal";
  import axios from "axios";
  import React, { useState, useEffect, useRef } from 'react';
  
  
  export default function CategoryCreate() {
    const [images, setImages] = useState([]);
    const [imageURLs, setImageURLs] = useState([]);
    
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    const formRef = useRef(null)
    const fileInputRef = useRef(null);
    
    const API_URL = 'https://api.chapanstore.com/api/productCategory/create';

    
    async function handleSubmit(event) {
      event.preventDefault(); 
      

      const formData = new FormData();
      formData.append('name', event.target.name.value);
      
      const imageInput = event.target.image;
      
      for (let i = 0; i < imageInput.files.length; i++) {
        formData.append(`image[]`, imageInput.files[i]);
      }
          console.log(formData);


    
      // Отправьте данные с использованием axios
       try { 
        const response = await axios.post(API_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', 
          'Authorization': `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`,
        },
      })
        console.log('Response', response);

        if (response.data.statusCode === 200) {
          const data = response.data;
          if (data && data.data && Array.isArray(data.data)) {
            // setProducts([data.data])
            console.log('SUccesful upload');
          } else {
            onOpen()
            console.log('ERORR');
          }
          }
          else {
            console.log(`Response : ${response.status}`);
          }
      
        
    } catch (error) {
      console.error(error);
      // Обработка ошибки, если запрос не удался
    }
  }
  

  function handleClose() {
    if (formRef.current) {
      formRef.current.reset();
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  
    onClose(); 
  }
  
  
    useEffect(() => {
      if (images.length < 1) return;
      const newImageUrls = [];
      images.forEach(image => newImageUrls.push(URL.createObjectURL(image)));
      setImageURLs(newImageUrls)
    }, [images]);
  
    function onImageChange(e) {
      setImages([...e.target.files]);
    }
  
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card 
        >
            
          {/* ЗДЕСЬ СНИЗУ ФОРМА ДЛЯ ОТПРАВКИ НОВОЙ КАТЕГОРИИ  */}
          {/* СЫЛЛКУ ЭКШЕНА НУЖНО ЗАМЕНИТЬ НА СЫЛЛКУ КАТЕГОРИИ */}
              
                <form ref={formRef} onSubmit={handleSubmit} encType="multipart/form-data"> 
            
                  <Box >
            
                   
                      <label for='text'>Наименование категории</label>
                      <Input type="text" name="name" placeholder="Здесь наименование категории"/>
                   
                    
                  </Box>

                  <Box mt="10px">
                    
                  <Button 
                    colorScheme='green'>
                  <label for='file'>
                    <span>Выбрать фотографии </span>
                    <Input 
                      ref={fileInputRef}
                      placeholder='Basic usage'
                      name="image" 
                      type="file" 
                      id="file"
                      className="input__file"
                      multiple 
                      accept='image/*' 
                      onChange={onImageChange}
                    />
                  </label>
                </Button>
            
              <Grid templateColumns="repeat(5, 1fr)" gap={6}>
    
                  {imageURLs.map(imageSrc => 
                    <Box w="80%" h="80%">
                      <img src={imageSrc} />
                    </Box>) 
                  }
    
              </Grid>

                  </Box>
            
                 <Box pt={{base: "20px"}}>
                     
                    <Input type="submit" value='Отправить'></Input>
                 </Box>
            </form>
            <SuccessModal isOpen={isOpen} onClose={handleClose} />
        </Card>
      </Box>
    )
  }
  