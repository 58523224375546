
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Image,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";

export default function OrdersTable() {

  const API_URL = 'https://api.chapanstore.com/api/order/getAll'
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [orderedProducts, setOrderedProducts] = useState([]);

  const [images, setImages] = useState(null);


  const url = 'https://api.chapanstore.com/media/';

  const { isOpen, onOpen, onClose } = useDisclosure(); // Используем useDisclosure

  useEffect(() => {
  
    axios.get(API_URL, { headers: {Authorization: `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`} })
      .then((response) => {
        // Извлекаем данные из ответа
        const data = response.data;
        if (data && data.data && Array.isArray(data.data)) {
          // Если данные существуют и это массив, устанавливаем их в состояние
          setCategories(data.data);
        } else {
          console.error('Ошибка при загрузке данных: Неверный формат данных', data);
        }
      })
      .catch((error) => {
        console.error('Ошибка при загрузке данных:', error);
      });
  }, []);
  
  // function handleCategoryChange(event) {
  //   setSelectedCategory(event.target.value);
  // `http://online.market/api/productcategory/delete/${selectedCategory}`
  // }

  function handleDeleteCategory(id) {
    setSelectedCategory(id);
    onOpen(); // Открываем модальное окно
  }

  
  function handleViewCategory(category) {
    setSelectedCategory(category); // Устанавливаем выбранную категорию в состояние
    onOpen(); // Открываем модальное окно
  }
   
  console.log(categories);


  
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Имя</Th>
            <Th>Фото</Th>

          </Tr>
        </Thead>
        <Tbody>
          {categories.map((category) => (
            <Tr key={category.id}>
              <Td>{category.id}</Td>
              <Td>{category.client_name}</Td>
              <Td>
                {/* <img src={ category.img} alt="" /> */}
                         {<Image boxSize="100px" objectFit="cover"
                            src={url + category.image["image_1"]} alt={category.name} />} 
              </Td>
              <Td>
                <Button
                  onClick={() =>  handleViewCategory(category)}
                  colorScheme="blue"
                >
                  Посмотреть заказ
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
        <ModalHeader>Заказ</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedCategory && (
              <>
                <p>ID: {selectedCategory.id}</p>
                <p>Имя клиента: {selectedCategory.client_name}</p>
                <p>Контакты клиента: {selectedCategory.social}</p>
                <p>Email клиента: {selectedCategory.Email}</p>
                {/* <p>Имя клиента: {selectedCategory.product_name}</p> */}
                {
  selectedCategory && selectedCategory.product_name &&
  Object.keys(selectedCategory.product_name).map((key, index) => (
    <div key={index}>
      <p>{selectedCategory.product_name[key]}</p>
    </div>
  ))
}



                {/* Дополнительные детали, которые вы хотите отобразить */}
                {/* <Image boxSize="100px" objectFit="cover" src={url + selectedCategory.image["path_1"]} alt={selectedCategory.product_name[`name_`]} /> */}
                {
  selectedCategory && selectedCategory.image &&
  Object.values(selectedCategory.image).map((imagePath, index) => (
    <div key={index}>
      <Image boxSize="100px" objectFit="cover" src={url + imagePath} alt={`Image ${index + 1}`} />
    </div>
  ))
}




              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Закрыть</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


    </Box>
  );
}