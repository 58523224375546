// import axios from 'axios';
import {
  Box,
  Grid,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  Select,
  useDisclosure,
  Button
} from "@chakra-ui/react";


import '../banner.css'
import axios from "axios";
import Card from "components/card/Card";
import SuccessModal from "components/succesModal/succesModal";
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';



export default function Banner() {
  const { isOpen, onOpen, onClose } = useDisclosure();


  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);

  const [categories, setCategories] = useState([]);
  const [product, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const formRef = useRef(null)
  const fileInputRef = useRef(null);


  useEffect(() => {
    const api = 'https://api.chapanstore.com/api/productCategory/getAll';

        axios.get(api, { 
          headers: {
            Authorization: `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`
          }
        })
      .then((response) => {
        // Извлекаем данные из ответа
        const data = response.data;
        if (data && data.data && Array.isArray(data.data)) {
          // Если данные существуют и это массив, устанавливаем их в состояние
          setCategories(data.data);
        } else {
          console.error('Ошибка при загрузке данных: Неверный формат данных', data);
        }
      })
      .catch((error) => {
        console.error('Ошибка при загрузке данных:', error);
      });
  }, []);


  async function handleSubmit(event) {
    event.preventDefault()

    const formData = new FormData();

    formData.append('category', event.target.category.value);
    formData.append('name', event.target.name.value);
    formData.append('description', event.target.description.value);
    formData.append('price', event.target.price.value);

    const imageInput = event.target.image;
    for (let i = 0; i < imageInput.files.length; i++) {
      formData.append(`image[]`, imageInput.files[i]);
    }

    try {
      const response = await axios.post('https://api.chapanstore.com/api/product/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`,
        },
      });
  
      console.log('Response', response);
  
      if (response.data.statusCode === 200) {
        const data = response.data;
        if (data && data.data && Array.isArray(data.data)) {
          setProducts([data.data]);
          console.log('Successful upload');
        } else {
          console.log('ERROR');
          onOpen(); 
          // Здесь не нужен setTimeout, так как мы уже дождались завершения запроса
        }
      } else {
        console.log(`Response: ${response.status}`);
      }
    } catch (error) {
      console.error(error);
      // Обработка ошибки, если запрос не удался
    }
  }


  // function handleSubmit(event) {
  //  event.preventDefault(); 
  //  // Отмена стандартного поведения формы
    
    
 
  //   const formData = new FormData(); // Создайте FormData

  //   formData.append('category', event.target.category.value);
  //   formData.append('name', event.target.name.value);
  //   formData.append('description', event.target.description.value);
  //   formData.append('price', event.target.price.value);
  
  //   const imageInput = event.target.image;
  //   for (let i = 0; i < imageInput.files.length; i++) {
  //     formData.append(`image[]`, imageInput.files[i]);
  //   }
  
  //   console.log(formData);
 
  //   axios.post('https://api.chapanstore.com/api/product/create', formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data', 
  //       'Authorization': `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`,
  //     },
  //   })
  //     .then((response) => {
  //       console.log('Response', response);
  //       // console.log(response);
  //       if (response.data.statusCode === 200) {
  //         const data = response.data;
  //       if (data && data.data && Array.isArray(data.data)) {
  //         setProducts([data.data])
  //                  onOpen()
  //         console.log('SUccesful upload');
  //       } else {
  //         console.log('ERORR');
  //         setTimeout(() => {
  //           window.location.reload()

  //         }, 500)
  //       }
  //       }
  //       else {
  //         console.log(`Response : ${response.status}`);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }

  function handleClose() {
    if (formRef.current) {
      formRef.current.reset();
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  
    onClose(); 
  }

  function handleCategoryChange(event) {
    setSelectedCategory(event.target.value);
  }

  useEffect(() => {
    if (images.length < 1) return;
    const newImageUrls = [];
    images.forEach(image => newImageUrls.push(URL.createObjectURL(image)));
    setImageURLs(newImageUrls)
  }, [images]);

  function onImageChange(e) {
    setImages([...e.target.files]);
  }

  // Object.keys(categories).map((category) => (
  // console.log(categories[category])
  // ))

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      <Card>
        <form ref={formRef} onSubmit={handleSubmit} encType="multipart/form-data"> 
  
                <Button colorScheme='green'>
                  <label for='file'>
                    <span>Выбрать фотографии </span>
                    <Input 
                      ref={fileInputRef}
                      placeholder='Basic usage'
                      name="image" 
                      type="file" 
                      id="file"
                      className="input__file"
                      multiple 
                      accept='image/*' 
                      onChange={onImageChange}
                    />
                  </label>
                </Button>
            
              <Grid templateColumns="repeat(5, 1fr)" gap={6}
              pt='10px' pb='10px'>
    
              {imageURLs.map((imageSrc, index) => 
                <Box key={index} w="80%" h="80%">
                  <img src={imageSrc} />
                </Box>) 
              }

    
              </Grid>
    
           
        
    
          <Box>
    
           
              <label for='text'>Наименование товара</label>
              <Input 
              type="text" 
              name="name" 
              placeholder="Наименование"/>
           
            
          </Box>
    
          <Box >
    
          
              <label for='text'>Описание товара</label>
              <Textarea 
              h='150px' 
              resize='none' 
              type="textarea" 
              name="description" 
              placeholder="Описание товара"/>
           
            
          </Box>
          
          <Box >
    
          
              <label for='text'>Напишите цену</label>
              <Input 
              type="number" 
              name="price" 
              placeholder="Цена товара"/>
           
            
          </Box>
          
          <Box>
            <FormControl>
              <FormLabel>Категории</FormLabel>
              <Select
                placeholder="Выберете категорию"
                name="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>

        <Input 
        type="submit" 
        value="Отправить">

        </Input>
        </form>
        <SuccessModal isOpen={isOpen} onClose={handleClose} />


      </Card>

    </Box>
  )
}

