/* eslint-disable */
import {
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Link,
  Image,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
// import { Link as ReachLink } from "@reach/router"
import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
import Menu from "components/menu/MainMenu";
import React, { useMemo, useEffect, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";


export default function DevelopmentTable(props) {

  const API_URL = "https://api.chapanstore.com/api/product/getAll"


  const [selectedProductId, setSelectedProductId] = useState(null);

  const handleDeleteClick = (productId) => {
    setSelectedProductId(productId); // Устанавливаем id выбранного продукта
    onOpen(); // Открываем модальное окно
  };

  // СНИЗУ УДАЛЕНИЕ ТОВАРА || PRODUCT REMOVAL FROM THE BOTTOM

  // const handleConfirmDelete = () => {
  //   const productIdToDelete = String(selectedProductId); // Преобразуем selectedProductId в строку
  //   fetch(`http://online.market/api/product/delete/${productIdToDelete}`, {
  //     method: 'DELETE',
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         onClose()
  //       } else {
  //         console.error(`Error of delete this product with id ${productIdToDelete}`)
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Ошибка при отправке запроса:', error);
  //     });
  //   onOpen(); 
  // };
  
  const handleConfirmDelete = () => {
   const productIdToDelete = String(selectedProductId);
  
    axios.delete(`https://api.chapanstore.com/api/product/delete/${productIdToDelete}`, { headers: {Authorization: `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`} })
      .then((response) => {const data = response.data;
        if (data && data.data && Array.isArray(data.data)) {
          console.log('if srabotal eto chudo');
        } else {
          onClose()
          console.error(`Error of delete this product with id ${productIdToDelete}`)
        }
      })
      .catch((error) => {
        console.error('Ошибка при отправке запроса:', error);
      });
    onOpen(); 
  };
  

  
  const deleteID = null;

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [products, setProducts] = useState([]);

  useEffect(() => {
  
    axios.get(API_URL, { headers: {"Authorization" : `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`} })
      .then((response) => {
        // Извлекаем данные из ответа
        const data = response.data;
        if (data && data.data && Array.isArray(data.data)) {
          // Если данные существуют и это массив, устанавливаем их в состояние
          setProducts(data.data);
        } else {
          console.error('Ошибка при загрузке данных: Неверный формат данных', data);
        }
      })
      .catch((error) => {
        console.error('Ошибка при загрузке данных:', error);
      });
  }, []);

  const { columnsData, tableData } = props;


  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => products, [products]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 21;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

// ССЫЛКА ДЛЯ МЕДИА ФАЙЛОВ
  const url ='https://api.chapanstore.com/media/'

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Products Table
        </Text>
        {/* <Menu /> */}
          {/* <Button colorScheme='green'>
            <Link href="http://localhost:3000/horizon-ui-chakra#/admin/nft-marketplace">
            Create Product
            </Link>
          </Button> */}
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "ID") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.row.original.id}
                        {/* {console.log(cell.row.original.id)} */}
                      </Text>
                    );
                  } else if (cell.column.Header === "TITLE") {
                    data = (
                      <Flex color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.row.original.name}
                      </Flex>
                    );
                  } else if (cell.column.Header === "PRICE") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                         {cell.row.original.price}
                      </Text>
                    );
                  } else if (cell.column.Header === "CATEGORY") {
                    data = (
                      <Flex align='center'>
                        {cell.row.original.category} 
                      </Flex>
                    );
                  } else if (cell.column.Header === "IMAGE") {
                    data = (
                      <Flex align='center'>
                         {
                            <Image 
                              boxSize="100px" 
                              objectFit="cover"
                              src={url + cell.row.original.image['path_1']} 
                              alt={cell.row.original.title} 
                            />
                          } 
                      </Flex>
                    );
                  } else if (cell.column.Header === "EDIT/DELETE") {
                    data = (
                      
                      <Stack spacing={1} align='center'>

                        
                        <Button
                        onClick={() => handleDeleteClick(row.original.id)} // Передаем ID товара в функцию удаления
                        colorScheme='red' width='100%'
                      >
                        Удалить
                      </Button>
                      </Stack>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Удаление продукта</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Вы уверены что хотите удалить товар?
          </ModalBody>
          <ModalFooter alignContent='center'>

          <Button colorScheme="red" onClick={handleConfirmDelete}>
              УДАЛИТЬ
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}