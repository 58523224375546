import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdOutlineCreate,
  MdOutlineCreateNewFolder,
  MdAspectRatio,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import OrdersTable from "views/admin/orders/components/OrdersTable";
import ClientsTable from "views/admin/clients/components/ClientsTable";
import MainPage from "views/admin/mainPage/components/MainPage";
import CategoryCreate from "views/admin/category/components/CategoryCreate";
import CategoriesTables from "views/admin/categories/components/CategoriesTables";
import RTL from "views/admin/rtl";

import PrivateRoute from "components/privateRoutes/PrivateRoutes";
// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  // {
  //   name: "Main Dashboard",
  //   layout: "/admin",
  //   path: "/default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: MainDashboard,
  // },
  {
    name: "Создать Товар",
    layout: "/admin",
    path: "/nft-marketplace",
    icon: <Icon as={MdOutlineCreate} width='20px' height='20px' color='inherit' />,
    component: (props) => (
      <PrivateRoute {...props}>
        <NFTMarketplace />
      </PrivateRoute>
    ),
    secondary: true,
  },
  {
    name: "Создать Категорию",
    layout: "/admin",
    path: "/category-create",
    icon: <Icon as={MdOutlineCreateNewFolder} width='20px' height='20px' color='inherit' />,
    component: CategoryCreate,
  },
  {
    name: "Все Категории",
    layout: "/admin",
    path: "/category-tables",
    icon: <Icon as={MdOutlineCreateNewFolder} width='20px' height='20px' color='inherit' />,
    component: CategoriesTables,
  },
  {
    name: "Товары",
    layout: "/admin",
    path: "/data-tables",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: DataTables,
  },
  {
    name: "Заказы",
    layout: "/admin",
    path: "/default",
    icon: (
      <Icon
        as={MdBarChart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: OrdersTable,
  },
  {
    name: "Управление главной страницей",
    layout: "/admin",
    path: "/banner-change",
    icon: <Icon as={MdAspectRatio} width='20px' height='20px' color='inherit' />,
    component: MainPage,
  },
  {
    name: "Выйти",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,

  },
  
];

export default routes;
