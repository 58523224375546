export const columnsDataDevelopment = [
  {
    Header: "ID",
    accessor: "name",
  },
  {
    Header: "TITLE",
    accessor: "tech",
  },
  {
    Header: "PRICE",
    accessor: "date",
  },
  {
    Header: "CATEGORY",
    accessor: "category",
  },
  {
    Header: "IMAGE",
    accessor: "image",
  },
  {
    Header: "EDIT/DELETE",
    accessor: "EDIT/DELETE",
  },
];
