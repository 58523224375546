import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Image,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";

export default function CategoriesTables() {

  const API_URL = 'https://api.chapanstore.com/api/productCategory/getAll'
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const url = 'https://api.chapanstore.com/media/';

  const { isOpen, onOpen, onClose } = useDisclosure(); // Используем useDisclosure

  useEffect(() => {
  
    axios.get(API_URL, { headers: {Authorization: `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`} })
      .then((response) => {
        // Извлекаем данные из ответа
        const data = response.data;
        if (data && data.data && Array.isArray(data.data)) {
          // Если данные существуют и это массив, устанавливаем их в состояние
          setCategories(data.data);
        } else {
          console.error('Ошибка при загрузке данных: Неверный формат данных', data);
        }
      })
      .catch((error) => {
        console.error('Ошибка при загрузке данных:', error);
      });
  }, []);
  
  // function handleCategoryChange(event) {
  //   setSelectedCategory(event.target.value);
  // `http://online.market/api/productcategory/delete/${selectedCategory}`
  // }

  function handleDeleteCategory(id) {
    setSelectedCategory(id);
    onOpen(); // Открываем модальное окно
  }
  
  const handleConfirmDelete = () => {
    const productIdToDelete = String(selectedCategory);
   
     axios.delete(`https://api.chapanstore.com/api/productcategory/delete/${selectedCategory}`, 
      { headers: {Authorization: `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`} })
       .then((response) => {
         if (response.data && response.data.success) {
           window.location.reload();
           onClose()
           console.log('Товар удалился все ок');
         } else {
           console.error(`Error of delete this product with id ${productIdToDelete}`)
         }
       })
       .catch((error) => {
         console.error('Ошибка при отправке запроса:', error);
       });
     onOpen(); 
   };
   
  


  
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Категории</Th>
            <Th>Фото</Th>

          </Tr>
        </Thead>
        <Tbody>
          {categories.map((category) => (
            <Tr key={category.id}>
              <Td>{category.id}</Td>
              <Td>{category.name}</Td>
              <Td>
                {/* <img src={ category.img} alt="" /> */}
                         {<Image boxSize="100px" objectFit="cover"
                            src={url + category.image} alt={category.name} />} 
              </Td>
              <Td>
                <Button
                  onClick={() => handleDeleteCategory(category.id)}
                  colorScheme="red"
                >
                  DELETE
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete Category</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Are you sure to remove this item?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="red" onClick={handleConfirmDelete}>
                        DELETE
                    </Button>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>


    </Box>
  );
}