/* eslint-disable */
import {
    Flex,
    Progress,
    Grid,
    Box,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Stack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Input,
    Textarea,
    Link,
    Image,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/card/Card";
  import SuccessModal from "components/succesModal/succesModal";
  // import { Link as ReachLink } from "@reach/router"
  import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
  import Menu from "components/menu/MainMenu";
  import React, { useMemo, useEffect, useState, useRef } from "react";
  import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
  } from "react-table";
  import axios from "axios";
  
  
  export default function MainPage(props) {
  
  
    const API_URL = 'https://api.chapanstore.com/api/order/getAll'
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    


    const [orderedProducts, setOrderedProducts] = useState([]);
  
    const [images, setImages] = useState(null);
    const [imageURLs, setImageURLs] = useState([]);

    const [inputKey, setInputKey] = useState(Date.now());


  
  
    const formRef = useRef(null)
    const fileInputRef = useRef(null);
  
    const url = 'https://api.chapanstore.com/media/';

    function handleClose() {
      if (formRef.current) {
        formRef.current.reset();
      }
  
      // if (fileInputRef.current) {
      //   fileInputRef.current.value = "";
      // }
      setInputKey(Date.now());
    
      onCloseSubmitModal(); 
    }
  
    const { isOpen, onOpen, onClose } = useDisclosure(); // Используем useDisclosure
    const { isOpen: isSubmitModalOpen, onOpen: onOpenSubmitModal, onClose: onCloseSubmitModal } = useDisclosure();

  
    // useEffect(() => {
    
    //   axios.get(API_URL, { headers: {Authorization: `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`} })
    //     .then((response) => {
    //       // Извлекаем данные из ответа
    //       const data = response.data;
    //       if (data && data.data && Array.isArray(data.data)) {
    //         // Если данные существуют и это массив, устанавливаем их в состояние
    //         setCategories(data.data);
    //       } else {
    //         console.error('Ошибка при загрузке данных: Неверный формат данных', data);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Ошибка при загрузке данных:', error);
    //     });
    // }, []);
    

    async function handleSubmitBanner(event) {
      event.preventDefault()
  
      const formData = new FormData();
  
  
      const imageInput = event.target.image;
      for (let i = 0; i < imageInput.files.length; i++) {
        formData.append(`image`, imageInput.files[i]);
      }
      formData.append("is_published", '1');

      console.log(formData);
      try {
        const response = await axios.post('https://api.chapanstore.com/api/mainBanner/create', formData, {
          headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`,
          },
        });
    
        console.log('Response', response);
    
        if (response.data.statusCode === 200) {
          const data = response.data;
          if (data && data.data && Array.isArray(data.data)) {
            setProducts([data.data]);
            console.log('Successful upload');
          } else {
            console.log('ERROR');
            onOpenSubmitModal(); 
            onClose();
            // Здесь не нужен setTimeout, так как мы уже дождались завершения запроса
          }
        } else {
          console.log(`Response: ${response.status}`);
        }
      } catch (error) {
        console.error(error);
        // Обработка ошибки, если запрос не удался
      }
    }



    async function handleSubmitBody(event) {
      event.preventDefault()
  
      const formData = new FormData();
  
  
      formData.append("title",  event.target.title.value);
      formData.append("description",  event.target.description.value);

      const imageInput = event.target.image;
      for (let i = 0; i < imageInput.files.length; i++) {
        formData.append(`image[]`, imageInput.files[i]);
      }
      formData.append("is_published", "true");
      
      console.log(formData);
      try {
        const response = await axios.post('https://api.chapanstore.com/api/mainBody/create', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`,
          },
        });
    
        console.log('Response', response);
    
        if (response.data.statusCode === 200) {
          const data = response.data;
          if (data && data.data && Array.isArray(data.data)) {
            setProducts([data.data]);
            console.log('Successful upload');
          } else {
            console.log('ERROR');
            onOpenSubmitModal(); 
            onClose();
            // Здесь не нужен setTimeout, так как мы уже дождались завершения запроса
          }
        } else {
          console.log(`Response: ${response.status}`);
        }
      } catch (error) {
        console.error(error);
        // Обработка ошибки, если запрос не удался
      }
    }
    // function handleCategoryChange(event) {
    //   setSelectedCategory(event.target.value);
    // `http://online.market/api/productcategory/delete/${selectedCategory}`
    // }
  
    function handleDeleteCategory(id) {
      setSelectedCategory(id);
      onOpen(); // Открываем модальное окно
    }
  
    
    function handleViewCategory(category) {
      setSelectedCategory(category); // Устанавливаем выбранную категорию в состояние
      onOpen(); // Открываем модальное окно
    }
    const [modalType, setModalType] = useState("");

    // Обработчики для разных модальных окон
    function openModalForPhoto() {
      setModalType("photoUpload");
      onOpen();
    }
    
    function openModalForTextAndPhotos() {
      setModalType("textAndPhotos");
      onOpen();
    }
    
    
    useEffect(() => {
      // Убедимся, что images существует и является массивом с элементами
      if (!images || images.length === 0) return;
    
      const newImageUrls = images.map(image => URL.createObjectURL(image));
      setImageURLs(newImageUrls);
    }, [images]);
    
  
    function onImageChange(e) {
      setImages([...e.target.files]);
    }
    
    return (
      <Card
        direction='column'
        w='100%'
        px='0px'
        overflowX={{ sm: "scroll", lg: "hidden" }}
        pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
          <Text
            fontSize='22px'
            fontWeight='700'
            lineHeight='100%'>
            Редактирование разделов и изображений
          </Text>
          {/* <Menu /> */}
            {/* <Button colorScheme='green'>
              <Link href="http://localhost:3000/horizon-ui-chakra#/admin/nft-marketplace">
              Create Product
              </Link>
            </Button> */}
        </Flex>
        <Table variant="simple">
    <Thead>
      <Tr>
        <Th>Задача</Th>
        <Th>Действие</Th>
      </Tr>
    </Thead>
    <Tbody>
      <Tr>
        <Td><b>Баннер</b> - Загрузка фотографии для баннера сайта</Td>
        <Td>
          <Button onClick={openModalForPhoto} colorScheme="blue">
            Открыть
          </Button>
        </Td>
      </Tr>
      <Tr>
        <Td><b>Раздел после баннера</b> - Редактирование текста и фотографий</Td>
        <Td>
          <Button onClick={openModalForTextAndPhotos} colorScheme="blue">
            Открыть
          </Button>
        </Td>
      </Tr>
    </Tbody>
  </Table>

  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{modalType === "photoUpload" ? "Загрузка фотографии" : "Редактирование текста и фотографий"}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {modalType === "photoUpload" ? (
          // Форма для загрузки фотографии
          <form ref={formRef} onSubmit={handleSubmitBanner} encType="multipart/form-data">

      <Box>
    
           
      <Button colorScheme='green'>
                  <label for='file'>
                    <span>Выбрать фотографии </span>
                    <Input 
                      key={inputKey}
                      ref={fileInputRef}
                      placeholder='Basic usage'
                      name="image" 
                      type="file" 
                      id="file"
                      className="input__file"
                      accept='image/*'                     
                      onChange={onImageChange}

                    />
                  </label>
                </Button>
                <Box
              pt='10px' pb='10px'>
    
              {imageURLs.map((imageSrc, index) => 
                <Box key={index} w="50%" h="50%" pl='10px' pb='10px'>
                  <img src={imageSrc} />
                </Box>) 
              }

    
              </Box>
 
  
</Box>
<Button colorScheme="blue" type="submit">
Загрузить
</Button>
  
</form>
          
        ) : modalType === "textAndPhotos" ? (
          // Форма для редактирования текста и фотографий
          <form ref={formRef} onSubmit={handleSubmitBody} encType="multipart/form-data">

      <Box>
    
           
      <Button colorScheme='green'>
                  <label for='file'>
                    <span>Выбрать фотографии </span>
                    <Input 
                      key={inputKey}
                      ref={fileInputRef}
                      placeholder='Basic usage'
                      name="image" 
                      type="file" 
                      id="file"
                      className="input__file"
                      multiple
                      accept='image/*'                     
                      onChange={onImageChange}

                    />
                  </label>
                </Button>
                <Box
              pt='10px' pb='10px'>
    
              {imageURLs.map((imageSrc, index) => 
                <Box key={index} w="50%" h="50%" pl='10px' pb='10px'>
                  <img src={imageSrc} />
                </Box>) 
              }

    
              </Box>
        <Box>
    
                
          <label for='text'>Заголовок</label>
          <Input 
          type="text" 
          name="title" 
          placeholder="Текст"/>
      
        
        </Box>

        <Box >


          <label for='text'>Текст под заголовком</label>
          <Textarea 
          h='150px' 
          resize='none' 
          type="textarea" 
          name="description" 
          placeholder="Текст"/>
      
        
        </Box>
 
  
</Box>
<Button colorScheme="blue" type="submit">
Загрузить
</Button>
  
</form>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="blue" mr={3} onClick={onClose}>
          Закрыть
        </Button>
        {/* Кнопки для действий в модальном окне */}
      </ModalFooter>
    </ModalContent>
  </Modal>

  <SuccessModal isOpen={isSubmitModalOpen} onClose={handleClose} />
      </Card>
    );
  }