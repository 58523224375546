import React from 'react';
import { 
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
 } from '@chakra-ui/react';

function SuccessModal(props) {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Успешно</ModalHeader>
      <ModalCloseButton onClick={props.onClose}/>
      <ModalBody>
      Успешно создано
      </ModalBody>
      <ModalFooter alignContent='center'>

      <Button colorScheme="red" onClick={props.onClose}>
          Закрыть 
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
  );
}

export default SuccessModal;
