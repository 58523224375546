// import axios from 'axios';
import {
  Box,
  Grid,
  Flex,
  Input,
  useDisclosure,
} from "@chakra-ui/react";


import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Card from "components/card/Card";
import axios from "axios";
import React, { useState, useEffect } from 'react';
const API_URL = 'https://api.chapanstore.com/api/adminAuth/signIn';



export default function SignIn() {
  const history = useHistory();
  const [redirect, setRedirect] = useState(false);

  const handleLogin = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('email', event.target.email.value);
    formData.append('password', event.target.password.value);

    console.log(formData);

    // Отправьте запрос на сервер с использованием Axios
    axios.post(API_URL, formData, {
      headers: {
        'Authorization': `Bearer kcDnsdjS923hciwenWd293diedwjbcCe$do923`,
      }
    })
      .then((response) => {
        const token = response.data.token;
        localStorage.setItem('token', token);
        console.log(token);
        setRedirect(true); // Устанавливаем флаг перенаправления после успешной аутентификации
      })
      .catch((error) => {
        console.error('Ошибка при регистрации:', error);
      });
  };

  // Перенаправление пользователя после успешной аутентификации
  if (redirect) {
    history.push('/admin');
  }
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
      <Card 
      >
          
            
              <form onSubmit={handleLogin}> 
          
                <Box >
          
                 
                    <label for='email'>Впишите свой Email</label>
                    <Input
                        name="email" 
                        type="text" 
                        placeholder="Здесь впишите email"
                    />
                 
                  
                </Box>

                <Box>
                  
              <label for='password'>
                <span>Ваш пароль</span>
                <Input name="password" 
                        type="password" 
                        placeholder="Здесь ваш пароль"/>
              </label>
          
            

                </Box>
          
               <Box pt={{base: "20px"}}>
                   
                  <Input type="submit" value='Войти'></Input>
               </Box>
          </form>
      </Card>
      </Flex>
    </Box>
  )
}
